/* eslint-disable no-alert */
// No need for translations here, it's purely for developers */
/* eslint-disable i18next/no-literal-string, docent/no-plain-text-jsx-attributes */
import { useQuery } from '@apollo/client'
import Button from 'client/components/Button/Button'
import FormField from 'client/components/Form/FormField/FormField'
import FormFieldSection from 'client/components/Form/FormField/FormFieldSection'
import SelectBox, { ISelectBoxOptions } from 'client/components/Form/SelectBox/SelectBox'
import gql from 'graphql-tag'
import _ from 'lodash'
import { useMemo, useState } from 'react'
import { GQLGuide, GQLLocale } from 'shared/graphql/types/graphql'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import styled from 'styled-components'
import useDeveloperPost from './useDeveloperPost'

type PrivateGuide = Pick<GQLGuide, 'id' | 'name' | 'visibilityType' | 'defaultLocale'>
type IPrivateGuideSelectOption = ISelectBoxOptions & { data: PrivateGuide }

const ALL_GUIDES = gql`
  query allGuides {
    guides {
      id
      name
      visibilityType
      defaultLocale {
        id
        code
      }
    }
  }
`

type ISelectedLocaleOption = ISelectBoxOptions & { data: GQLLocale }
const ALL_LOCALES = gql`
  query allLocales {
    locales {
      id
      englishName
      code
    }
  }
`

const StyledSelectBox = styled(SelectBox)`
  color: black;
  width: 313px;
`

const FormSection = styled.section`
  display: flex;
  column-gap: var(--spacing-medium);
`

const ChangeGuideDefaultLocale = () => {
  const [selectedPrivateGuide, setSelectedPrivateGuide] = useState<IPrivateGuideSelectOption>()

  const { data: allGuidesData } = useQuery<{ guides: PrivateGuide[] }>(ALL_GUIDES)

  const privateGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .filter({ visibilityType: GuideVisibilityType.PRIVATE })
        .orderBy('name')
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} — (${guide.defaultLocale.code}) — (${guide.id})`,
          data: guide
        }))
        .value(),
    [allGuidesData?.guides]
  )

  const [selectedDefaultLocale, setSelectedDefaultLocale] = useState<ISelectedLocaleOption>()
  const { data: allLocalesData } = useQuery<{ locales: GQLLocale[] }>(ALL_LOCALES)
  const allLocaleOptions = useMemo(
    () =>
      _(allLocalesData?.locales)
        .orderBy('englishName')
        .map((locale) => ({
          value: locale.id,
          label: `${locale.englishName} — (${locale.code})${
            locale.id === selectedPrivateGuide?.data.defaultLocale.id ? ' — [Original DEFAULT]' : ''
          }`,
          data: locale
        }))
        .value(),
    [allLocalesData?.locales, selectedPrivateGuide?.data.defaultLocale.id]
  )

  const [postChangeDefaultLocale] = useDeveloperPost(
    '/api/developer/change-default-locale',
    'Change Default Locale'
  )

  const handleChangeDefaultLocaleClicked = () => {
    if (!selectedPrivateGuide || !selectedDefaultLocale) {
      return
    }
    const { data: guide } = selectedPrivateGuide
    const { data: locale } = selectedDefaultLocale

    const isConfirmed = window.confirm(
      `Are you sure you want to change "${guide.name}" default locale from [${guide.defaultLocale.code}] to [${locale.code}]?`
    )

    if (isConfirmed) {
      postChangeDefaultLocale({ guideId: guide.id, localeId: locale.id })
    }
  }

  return (
    <FormFieldSection
      label={
        <h4 style={{ paddingBottom: 24 }}>
          <strong>Change Guide Default Locale</strong>
        </h4>
      }
      description={
        <p>
          The purpose of this field is to allow private guides to change their default locale to a
          different default locale. This will only affect translatable content (ex Items and
          Exhibition). We apply the following logic for each translatable row:
          <li>
            If a translation with the new locale exists for a parent row, we will swap the parent
            and that translation`s row translatable content.
          </li>
          <li>
            If a translation with the new locale doesn`t exist for a parent row, we will create a
            new translation row using the parent`s translatable content with the old locale and
            leave the parent as-is.
          </li>
        </p>
      }
    >
      <FormSection>
        <FormField label="Private Guide">
          <StyledSelectBox
            placeholder="Select a private guide..."
            options={privateGuideOptions}
            onChange={(e) => {
              const privateGuideOption = _.find(privateGuideOptions, { value: e.target.value })
              setSelectedPrivateGuide(privateGuideOption)
              const selectedDefaultLocaleOption = _.find<ISelectedLocaleOption>(allLocaleOptions, {
                value: privateGuideOption?.data.defaultLocale.id
              })
              setSelectedDefaultLocale(selectedDefaultLocaleOption)
            }}
          />
        </FormField>
      </FormSection>
      <FormField label="Select New Current Default Locale" disabled={!selectedDefaultLocale}>
        <StyledSelectBox
          placeholder="Select a private guide..."
          options={allLocaleOptions}
          value={selectedDefaultLocale}
          isDisabled={!selectedDefaultLocale}
          onChange={(e) => {
            const selectedOption = _.find(allLocaleOptions, { value: e.target.value })
            setSelectedDefaultLocale(selectedOption)
          }}
        />
      </FormField>
      <FormField>
        <Button
          label="Change Default Locale"
          disabled={
            !selectedDefaultLocale ||
            selectedDefaultLocale.value === selectedPrivateGuide?.data.defaultLocale.id
          }
          onClick={handleChangeDefaultLocaleClicked}
        />
      </FormField>
    </FormFieldSection>
  )
}
export default ChangeGuideDefaultLocale
