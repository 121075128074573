/**
 * Values for keys in this enum must match the feature flag name in
 * the database table docent.feature_flags
 */
export enum FeatureFlagKey {
  GRAPHQL_CACHING = 'GRAPHQL_CACHING',
  GRAPHQL_EXPLORE_CACHING = 'GRAPHQL_EXPLORE_CACHING',
  MAINTENANCE_MODE = 'MAINTENANCE_MODE',
  ANALYTICS = 'ANALYTICS',
  AUTH_RATE_LIMIT = 'AUTH_RATE_LIMIT',
  DEVELOPER_MODE = 'DEVELOPER_MODE',
  VIDEO_TRANSLATIONS_AUDIO_TRACKS = 'VIDEO_TRANSLATIONS_AUDIO_TRACKS',
  BULK_UPLOAD = 'BULK_UPLOAD',
  LOANER_REPORT = 'LOANER_REPORT',
  MARKETING = 'MARKETING',
  CHANGE_USER_PERMISSION_TYPE = 'CHANGE_USER_PERMISSION_TYPE',
  EDIT_GOOGLE_MAPS_INFO = 'EDIT_GOOGLE_MAPS_INFO',
  LANGUAGE_SELECTION = 'LANGUAGE_SELECTION',
  DOWNLOAD_ASSETS = 'DOWNLOAD_ASSETS',
  CHANGE_USER_MFA_TYPE = 'CHANGE_USER_MFA_TYPE',
  EMAIL_MFA = 'EMAIL_MFA',
  USAGE_REPORTS = 'USAGE_REPORTS',
  GUIDE_NON_NAVIGABLE_LAT_LONG = 'GUIDE_NON_NAVIGABLE_LAT_LONG',
  PST_ART_GUIDE_LINKING = 'PST_ART_GUIDE_LINKING',
  MANAGE_MOBILE_WEB = 'MANAGE_MOBILE_WEB'
}

export type FeatureFlags = {
  [key in FeatureFlagKey]: boolean
}
