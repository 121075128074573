import { usePost } from 'client/hooks/api'
import { flash } from 'client/redux/actions/flash'
import _ from 'lodash'
import { useDispatch } from 'react-redux'

const useDeveloperPost = (url, description, onSuccess = _.noop) => {
  const dispatch = useDispatch()
  return usePost(url, {
    onSuccess: (...args) => {
      dispatch(flash(`${description}: SUCCESS!`))
      onSuccess(...args)
    },
    onError: () => {
      dispatch(flash(`${description}: ERROR!`, true))
    }
  })
}

export default useDeveloperPost
